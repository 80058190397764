/* global tw */
import React from "react";
import styled from "styled-components";
// import { Link } from "gatsby";

const FlexWrapper = styled.div`
	${tw`flex flex-wrap -mx-3`};
`;

// const FlexHalf = styled.div`
// 	${tw`w-full md:w-1/2 flex md:px-3`};
// `;

const FlexThreeQuarters = styled.div`
	${tw`w-full md:w-1/2 lg:w-3/4 flex md:px-3`};
`;

const FlexOneQuarter = styled.div`
	${tw`w-full md:w-1/2 lg:w-1/4 flex md:px-3`};
`;

const GridList = ({ post, items, featuredPost, extraitems, ...props }) => {
	const entry = items[0];
	return (
		<FlexWrapper>
			<FlexThreeQuarters>
				<div className="bg-green flex h-64 md:h-auto flex-1 items-center mb-6 md:mb-0">
					<div
						className="w-full h-full bg-no-repeat bg-cover"
						style={{
							backgroundImage:
								!entry.featuredImage || !entry.featuredImage.length
									? "url(https://placehold.it/600x400)"
									: "url(" +
									  entry.featuredImage[0].url +
									  "?fm=jpg&auto=compress,enhance,format&fit=crop&max-w=600&max-h=400" +
									  ")",
							backgroundPosition:
								!entry.featuredImage || !entry.featuredImage.length
									? "center top"
									: entry.featuredImage[0].focalPoint.x * 100 +
									  "%" +
									  " " +
									  entry.featuredImage[0].focalPoint.y * 100 +
									  "%"
						}}
					/>
				</div>
			</FlexThreeQuarters>
			<FlexOneQuarter>
				<div className="flex flex-wrap w-full insidegrid overflow-x-hidden">
					{items.slice(1).map((entry, i) => (
						<div
							key={i}
							className={
								"w-full flex h-64 insidesubblock item-" +
								i +
								(i === items.length - 2 ? " mb-0" : " mb-6")
							}
						>
							<div className="bg-red flex flex-1 items-center">
								<div className="w-full text-center">
									<h1>{entry.title}</h1>
								</div>
							</div>
						</div>
					))}
				</div>
			</FlexOneQuarter>
		</FlexWrapper>
	);
};

export default GridList;

// this works because we sliced one off first. if we're not doing that, we need to change the length - 2
