import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import NewGridList from "../components/NewGrid";

class TestingAlso extends Component {
	render() {
		const {
			data: {
				recraft: { main, extra }
			}
		} = this.props;

		return (
			<Layout>
				<div
					className="container my-12 min-h-full p-6"
					style={{ backgroundColor: "#f5f7f8" }}
				>
					<NewGridList items={main} featuredPost={main[0]} extraitems={extra} />
				</div>
			</Layout>
		);
	}
}

export default TestingAlso;

export const query = graphql`
	query {
		recraft {
			main: entries(section: [news], limit: 3, offset: 40) {
				title
				uri
				... on Recraft_News {
					preparseGlobalCategory
					featuredImage {
						url
						id
						focalPoint {
							x
							y
						}
					}
				}
			}

			extra: entries(section: [news], limit: 6, offset: 10) {
				title
				uri
				... on Recraft_News {
					preparseGlobalCategory
					featuredImage {
						url
						id
						focalPoint {
							x
							y
						}
					}
				}
			}
		}
	}
`;
